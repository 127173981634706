import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css' 
import '../node_modules/bootstrap/dist/js/bootstrap.bundle' 
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import PayPal from './Components/PayPal';

import Home from './Components/Home';
import Projects from './Components/Projects';
import DonatePage from './Components/Support';
import Footer from './Components/Footer';
import HeroPage from './Components/HeroPage';
import NavBar from './Components/NavBar';
import {  Routes, Route } from 'react-router-dom';
import DonorPage from './Components/DonorPage';
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <PayPalScriptProvider>
        <Routes>
        <Route path= '*' exact element={<Home/>} />
        <Route path= '/DonorPage'  element={<DonorPage/>} />
        <Route path= '/PalPal'  element={<PayPalButtons/>} />
        </Routes>
        </PayPalScriptProvider>


      </header>
    </div>
  );
}

export default App;
