  
import {Nav, Navbar, Container, NavDropdown, NavLink} from 'react-bootstrap';  
import '../Components/CSS/Home.scss'
import logo from '../Components/IMAGE/OHARAS.webp'
import { HashLink as Link } from 'react-router-hash-link';
import { useNavigate } from "react-router-dom";

function NavBar() {  
  const navigate = useNavigate()
  return (  
    <Navbar  expand="lg"  className=' bg-black/30 backdrop-brightness-75 nav-donation' >  
    <div className="container ">

      
      <NavLink className='navbar-brand  text-decoration-none' href="/Home" >
      <img  
        src={logo}  
        width="80"  
        height="80"  
        className="d-inline-block align-top"  
        alt="Brand logo"  
      />
      </NavLink>  
     
  

        <Nav className="me-auto  link-donation">  
          <NavLink href="/Home" className="linker">
         Back to Home page
       </NavLink>  
     </Nav>  
  
     </div>

  </Navbar>  
  );  
}  
export default NavBar;  