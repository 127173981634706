import React from 'react'
import '../Components/CSS/AboutKevin.scss'
import image2 from '../Components/IMAGE/kevin-img.png'
import { useNavigation } from 'react-router-dom'



const AboutKevin = () => {

  return (
    <div className="container AboutKevin d-flex flex-col justify-center align-items-center w-screen  overflow-hidden relative top-28 m-auto bg-gray-50" >
 
    <h3 className= ' title  text-center text-bold p-5 w-full bg-light text-4xl font-extrabold bg-blue-600/30 backdrop-brightness-75 shadow-2xl' 
    
    > <span className='text-light'>
    Getting to Know </span><u style={{color:'#bc8803'}}>Kevin O'Hara</u>: <span className='text-light'>A Snapshot</span>
   </h3>
  
   <div className='wrapper-div'>
   <div className="image-box w-full">
    <img src={image2} alt="" className="imgs w-full img-fluid" />
        </div>
      <div className=" text-box  bg-slate-100 pt-3">
     <p className='w-full bg-gray-50'>
     Meet Fr. Kevin O'Hara, a remarkable St. Patrick missionary priest hailing from Stranorlar, a vibrant community in County Donegal, Republic of Ireland. As a shepherd in God's vineyard, he was ordained with a divine purpose—to guide his flock towards the path of salvation. However, to the people he encountered, Fr. Kevin was much more than a spiritual leader. He embodied hope for the hopeless and served as a beacon of compassion, alleviating suffering through spiritual upliftment and fighting against injustice. Right after his ordination, Fr. Kevin O'Hara's mission led him to Nigeria, where he embarked on a transformative journey of service. His practical approach to helping the poor, oppressed, and exploited earned him widespread admiration. Going beyond the pulpit, he took the Gospel to the streets, demonstrating an unwavering commitment to those in need.
     </p>
  
        <p className='w-full bg-gray-50'>
        Fr. Kevin's philanthropic endeavors extended far and wide, encompassing disease prevention and educational empowerment. In Nigeria, he fearlessly tackled the alarming water-borne disease, Guinea worm, by implementing essential pipe-borne water systems for the affected community. Furthermore, he championed education by generously supplying computers and advanced IT equipment to a local commercial school, leaving a profound and positive mark on the students' educational prospects. 
        </p>
        <p className='w-full bg-gray-50'>
        Furthermore, in a testament to his unwavering dedication to education, Fr. Kevin sponsored a blind boy to pursue his studies. Through this act of profound compassion, he exemplified the belief that every individual, regardless of their circumstances, deserved an opportunity to receive a quality education. This sponsorship not only transformed the life of the young boy but also highlighted Fr. Kevin's commitment to inclusivity and empowering those who faced additional challenges in accessing educational opportunities.
        </p>
        <p className='w-full bg-gray-50'>
        In the 1980s, Fr. Kevin O'Hara established the Human Rights and Conflict Resolution Center in Abakaliki, taking on cases of social injustice and human rights abuses. The center became instrumental in resolving thousands of cases, ranging from police brutality and illegal detention to child abuse, domestic violence, and political oppression. His pursuit of justice and fairness extended to the oil-rich Niger Delta, where he took on cases related to environmental damage caused by oil spills.
     </p>
     <p className='w-full bg-gray-50'>
     Recognizing the need to engage with corporations on ethical and social issues, Fr. Kevin founded the Centre for Social & Corporate Responsibility in 2001. Through this initiative, he encouraged oil companies to embrace corporate responsibility, sustainability, governance, and human rights. He also played a key role in founding the Stakeholders Alliance for Corporate Accountability in 2012, empowering over 40 oil and gas host communities with sustainable development projects.
     </p>
     <p className='w-full bg-gray-50'>
     In 2020, Fr. Kevin O'Hara was bestowed with the prestigious Presidential Distinguished Service Award. This recognition honored his tireless efforts in promoting Irish heritage and values in Nigeria and across the globe. Conclusively, Fr. Kevin O'Hara's life and work exemplify an unwavering commitment to compassion, justice, and service. From his humble beginnings in Stranorlar to his impactful endeavors in Nigeria, he remains an inspiration to all. As a true shepherd in God's vineyard, he continues to touch countless lives, making the world a better place through his indomitable spirit of hope and his dedication to the service of humanity.
     </p>
  
     </div>
      </div>
  </div>
  )
}

export default AboutKevin;