// AboutUs.js

import React from 'react';
import './CSS/AboutUs.scss'
import { useNavigate } from 'react-router-dom'


const AboutUs = () => {
  const navigate = useNavigate();
  return (
    <div className="about-us relative top-28 bg-white" id ='About'>
      <h2>About Us</h2>
      <h6 className='bg-white about-page'>
      Fr. Kevin O'Hara's Foundation is a non-profit organization committed to uplifting humanity, supporting communities, and promoting positive social change. The mission is rooted in the belief that every individual deserves to live a life of dignity and empowerment. The vision is simple yet profound: to create a world where compassion, support, and kindness are the cornerstones of society. Through  charitable initiatives, this foundation strives to uplift communities, particularly those affected by poverty, disease, and injustice. As we continue on this meaningful journey, we invite you to join us. With your support and generosity, we can make a lasting impact and transform lives for the better. Together, we can be a force for positive change and be the hands that heal, the hearts that understand, and the voices that advocate for the vulnerable
      </h6>
      
      <button href="#" className=" donate-btn text-decoration-none  my-2" onClick={()=>navigate("/DonorPage")}>Donate Now</button>
  
    </div>
  );
};

export default AboutUs;
