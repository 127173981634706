import React from 'react'
import './CSS/Footer.scss'
import ContactUs from './ContactUs'

const Footer = () => {
  return (


<footer className="text-center text-lg-start  footer-cover z-10">
 {<ContactUs/>}
  <section className=" footer-wrapper">
    <div className="container text-center text-md-start mt-5 footer-inner-section">
      <div className="row mt-5">
        <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
          <h5 className="text-uppercase fw-bold mb-4">
          K'OHARA FOUNDATION
          </h5>
          <p style={{fontFamily: 'sans-serif', fontSize:'16px'}} className= "footer-logo">
          At the Fr. Kevin O'Hara Foundation, we are dedicated to dismantling the barriers of illiteracy, dehumanization, and social injustice. {/* Through unwavering humanitarian support, we strive to alleviate the plight of those affected by diseases, extend the light of education to the less fortunate, and ardently combat social injustices on a global canvas */}
          </p>
        </div>

        <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
          <h5 className="text-uppercase fw-bold mb-4">
            Services
          </h5>
          <p>
            <a href="#!" className="text-reset">Prayer</a>
          </p>
          <p>
            <a href="#!" className="text-reset">Counselling</a>
          </p>
          <p>
            <a href="#!" className="text-reset">Teaching</a>
          </p>
        
        </div>

       

        <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-3">
          <h5 className="text-uppercase fw-bold mb-4">Contact</h5>
          <p  className='d-flex gap-2 align-items-center justify-center'>
          <svg  xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#f8fafbd9" class="bi bi-envelope" viewBox="0 0 16 16">
        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
        </svg>
            kevinpatohara@gmail.com
          </p>
          <p className='d-flex gap-2 align-items-center justify-center'>
            
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#eff6f9d9" class="bi bi-telephone-fill" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
</svg>
   + 01 234 567 88
   
   </p>
  <p className='d-flex gap-3 align-items-center justify-center'>
   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#edf3f5d9" class="bi bi-telephone-fill" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
</svg>
            
  + 01 234 567 89
  </p>
        </div>
      </div>
    </div>
  </section>

  <div className="text-center p-4  z-40 footer-bottom d-flex justify-center align-middle flex-wrap" >
   <span>© 2023 Copyright:
    <span className="text-reset foundation">oharafoundation.com</span></span>
    <a className="text-reset relative left-3 profile" href="https://igboculturalassociation.com/Profile/"><span style={{   color:'#bc8803'}}>  Developed by DinoTech</span>
  </a>
  </div>
</footer>


  )
}

export default Footer