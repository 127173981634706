import React from 'react'
import image1 from '../Components/IMAGE/ohara2.webp'
import logo from '../Components/IMAGE/logo.webp'
import NavBar from './NavBar'
import AboutUs from './AboutUs'
import HeroPage from './HeroPage'
import AboutKevin from './AboutKevin'
import Projects from './Projects'
import DonatePage from './Support'
import Footer from './Footer'
import ContactUs from './ContactUs'
import Support from './Support'
import './CSS/Home.scss'



const Home = () => {
  return (
   
<div className="home">
  <NavBar/>
  <HeroPage/>
  <AboutUs/>
  <AboutKevin/> 
  <Projects/>
  <Support/>
 <Footer/>
 </div>
  )
}

export default Home

