import React from 'react';
import './CSS/Support.scss'
import { useNavigate } from 'react-router-dom';

function DonatePage() {
  const navigate  = useNavigate()
  return (
    <div className="donate-page relative bg-gray-50" id ='Support'>
      <h2 className=' py-4 bg-black text-light'>Please Support <span style={{color:'#bc8803'}}>Fr. Kevin O'Hara's</span> Foundation</h2>
    {/*   <p className='sub-h2'>Your generous donation can make a difference in the lives of those in need.</p> */}
      <h6 className='text-left support-text'>

      
      Father Kevin's philanthropic endeavours are constantly growing, underscoring the need for support from individuals like you to help fulfil his financial needs. Your contribution can have a profound impact by alleviating the suffering of those afflicted by diseases, providing education to the less fortunate, and actively combatting social injustices on a global scale. <br/>
   
   I encourage you to contemplate contributing to Father Kevin's charitable mission. Even a modest donation can create ripples of change in the lives of those striving to overcome challenges. Father Kevin's remarkable efforts inspire us all, and your support can empower him to sustain his profound influence on the betterment of our world.


   </h6>
      <button className="donate-button" onClick={()=>{
        navigate('/DonorPage')
      }}>Donate Now</button>
    </div>
  );
}

export default DonatePage;
