import React, { useState } from 'react';
import './CSS/Newdonor.scss';
import { NavLink } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PayPal from './PayPal';
import NavBar from './NavbarDonation';




const DonorPage = () => {

  const [paymentMethod, setPaymentMethod] = useState(false);
  const [PayPalCard, setpayPalCard] = useState(false);
  const [toggleBtn, settoggleBtn] = useState(true);
  const [donation, setDonation] = useState(0);

const handleDonationSubmit= (e)=>{
 e.preventDefault();
}
 const handleAmountChange =(e)=>{
  setDonation(e.target.value)
 }

  return (
    <>
    {<NavBar/>}
    <section className='relative mt-16 h-full bg-lime-500/30 backdrop-brightness-75 bg-fixed'>
    <div className="donation-container w-full p-5 bg-indigo-900/70 backdrop-brightness-75 relative ">
      <h2 className='text-ligh'>Make a Donation</h2>
        <div className="donation-amount w-100% d-flex flex-col justify-center align-middle">
          <label className='m-auto text-light'>Donation Amount (Any ($))</label>
        
       <form onSubmit={handleDonationSubmit} className="donation-form my-4 relative left-52">
        <div className="form-group">
   
          <input
            type="number"
            id="donationAmount"
            value={donation}
            onChange={handleAmountChange}
            min="1"
            className="donation-input px-3 py-2"
          />
        </div>
       
      </form>
       
        </div>
        





  <div className="dropdown relative top-0 payment-method w-100% d-flex flex-col justify-center align-items-center">
    { toggleBtn &&
  <button className=" dropdown-toggle m-auto" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
  Donate with Card or Bank Transfer 
 </button>
}
  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    <li className='list-items'>
      <button className="dropdown-item text-light" href="#" onClick={()=>{

       setpayPalCard(true)
      }}>Credit/Debit Card</button>
      </li>
    <li  className='list-items relative top-2'>
      <button className="dropdown-item text-light" href="#"  value="bank" onClick={()=>setPaymentMethod(true)}>Bank Transfer</button>
      </li>
      
  </ul>
</div>

        {paymentMethod  && (
          <div className="bank-details" onClick={()=>setPaymentMethod(false)}>
            <h5 className='pb'>Please use the Bank details below</h5>
            <div className="iban">
              <label>IBAN:</label>
              <p>IE11AIBK93736323942109</p>
            </div>
            <div className="BIC">
              <label>BIC:</label>
              <p>AIBKIE2D</p>
            </div>
           
            <div className="account-number">
              <label>Account Number:</label>
              <p>26942-109</p>
            </div>
            <div className="account-name">
              <label>Account Name:</label>
              <p>Fr.Kevin O'hara</p>
            </div>
         
          
         <button className="credit-btn font-medium bg-black backdrop-brightness-75" href="#"
         onClick={()=>{
          setpayPalCard(true)
         }}>Use 
          Card Instead</button>
          </div>
          
        )}
    </div>
    {PayPalCard &&
    <div className="cards w-full h-full fixed-top bg-slate-900/95"onClick={()=>{
      setpayPalCard(false)
    }}>
     <div className="pay-pal d-flex  relative top-32 flex-col mt-12 pt-10 align-items-center px-32 md:px-5 mx-auto justify-center" onClick={()=>{
      setpayPalCard(false)
     }}>
      <div className="pal-pal-button w-100 h-70 bg-slate-900 relative top-0">
     <p className='text-white shadow-white font-extrabold  font-4xl display-block text-center py-3'>Make a Donation</p>
     <p className=' pay-pal-inner relative top  display-block '><PayPal/></p>
     </div>
     </div>
    </div>
}
    </section>
    </>
  );
};

export default DonorPage;
