import React from 'react'
import './CSS/ContactUs.scss'
import { useNavigate } from 'react-router-dom'

const ContactUs = () => {
    const navigate = useNavigate()
  return (

<div className="contact-us w-full d-flex flex-col justify-center align-items-center p-5 pb-4 mb-5 " id ='ContactUs'>
<section className="mb-4  contact-inner ">
    <header className="text-light  z-50">
        <h4 className="h1-responsive ">Contact us</h4>
 
    <p className="text-center w-responsive mx-auto px-5  ">
Please don't hesitate to reach out to us using the contact information provided, and our team will promptly respond to your inquiry.</p>
</header>
    <div className="row relative my-16 top-16">

      
        <div className="col-md-12 mb-md-0 mb-5 ">
            <form id="contact-form" name="contact-form " action="mail.php" method="POST">

                <div className="row">

                    <div className="col-md-6 mb-3">
                        <div className="md-form mb-0  relative my-4 top-4">
                        <label for="name" className="">Your name</label>
                            <input type="text" id="name" name="name" className="form-control"/>
                            
                        </div>
                    </div>
                    
                    <div className="col-md-6 mb-3">
                        <div className="md-form mb-0 relative my-4 top-4">
                        <label for="email" className="">Your email</label>
                            <input type="text" id="email" name="email" className="form-control"/>
                           
                        </div>
                    </div>
                

                </div>
                
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <div className="md-form relative top-6">
                        <label for="subject" className="">Subject</label>
                            <input type="text" id="subject" name="subject" className="form-control"/>
                          
                        </div>
                    </div>
                </div>
               
                <div className="row">

               
                    <div className="col-md-8 mb-3">

                        <div className="md-form relative my-4 top-4">
                        <label for="message">Your message</label>
                            <textarea type="text" id="message" name="message" rows="2" className="form-control md-textarea" height='300'></textarea>
                            
                        </div>

                    </div>
                </div>
          

            </form>

            <div className="text-center text-md-left d-flex flex-start justify-start form-btn ">
                <button className="btn btn-primary bg-black/30 backdrop-brightness-75 mb-2 " 
                onClick={()=>navigate('/DonorPage')}>Send</button>
            </div>
            <div className="status"></div>
        </div>
     
      
    </div>

</section>



    </div>
  )
}

export default ContactUs