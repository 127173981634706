

import React from 'react';
import './CSS/Project.scss'
import image from './IMAGE/ohara3.webp'
import image2 from './IMAGE/insight.png'

const Projects = () => {
  return (
    <>
    <div className="projects container bg-gray-50 ">
      <h3 className=' py-4  z-10 text-4xl font-extrabold d-flex justify-center align-items-center  bg-blue-600/30 backdrop-brightness-75'>
        <span className='relative top-1 text-light'> Fr. <span style={{ color: '#bc8803'}}> Kevin O'Hara's </span>Projects</span>
        </h3>

      <h6 className='sub-tittle relative' id  = 'Projects'>
      Discovering the impactful projects undertaken by Fr. Kevin O'Hara
        in his relentless pursuit of positive change and social transformation.

      </h6>
      <ul className="project-list relative top-7 bg-white">
        <li>
          <div className="project-info">
            <h5>Empowering Communities</h5>
            <h6 className='bg-gray-50'>
            Fr. Kevin envisions a world where all individuals lead meaningful lives and contribute to society's progress. His dedication lies in elevating local communities through resources, knowledge, and support. This empowerment encompasses disease prevention, education, social justice, and sustainable growth - fostering self-sufficiency and unity. Fr. Kevin's initiatives, such as combating diseases; advancing education; and advocating for human rights, empower communities to thrive, preserving culture and promoting inclusivity. In all, he strives for communities to shape their destinies, fostering a brighter future and a better society
            </h6>
          </div>
          <div className="project-image">
            <img src={image} alt="Project 1" />
          </div>
        </li>
        <li>
          <div className="project-info">
            <h5>Education for All</h5>
            <h6 className='bg-gray-50'>
            Fr. Kevin O'Hara's philanthropic endeavours vividly exemplify his unwavering commitment to the principle of "Education for All." This overarching commitment is beautifully illustrated through two impactful initiatives that focus on ensuring equal educational opportunities for individuals from diverse backgrounds and circumstances.
            </h6>

           <h6 className='page bg-gray-50'>
            <strong>Championing Access to Technology and Knowledge:</strong> Fr. Kevin generously provided computers and advanced IT equipment to a local community school enhancing the school's technological capabilities as well as empowering students with the tools necessary to thrive in a rapidly evolving world. This directly addresses the digital divide that can hinder learning in underserved communities. This effort not only expands educational horizons but also signifies his belief in the transformative power of knowledge, fostering an environment where students can reach their full potential and contribute to society.
            </h6>

            <h6 className='page bg-gray-50'>
              <strong>Inclusivity and Empowerment of Vulnerable Individuals: </strong> Fr. Kevin's sponsorship of a blind boy to pursue his studies reflects his profound understanding of the fundamental principle that education should transcend all barriers. By extending educational opportunities to a student with visual impairment, he exemplifies the essence of "Education for All." This act of compassion and inclusivity goes beyond providing access to education; it uplifts an individual's spirit, instils hope, and validates the belief that everyone deserves a chance to learn and grow.
            </h6>
          </div>
          {/* <div className="project-image">
            <img src={''} alt="Project 2" />
          </div> */}
        </li>
        {/* Add more project items as needed */}
      </ul>
    </div>
    </>
  );
};

export default Projects;
