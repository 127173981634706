
import React from 'react'
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";


const PayPal = () => {
  return (
    <div style={{maxWidth:''}}>
      
      <PayPalButtons/>
      </div>
  )
}

export default PayPal