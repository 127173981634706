import React from 'react'
import '../Components/CSS/HomePage.scss'
import image1 from '../Components/IMAGE/ohara2.webp'
import { useNavigate } from 'react-router-dom'




const HeroPage = () => {
  const navigate = useNavigate()
  return (

<div className="hero  container-fluid z-10">
<caption className='thanks-message relative  d-flex flex-col h-auto'>
  <span className='span1'>Thanks for your support <i class="fa fa-handshake-o text-orange-100">
    </i> 
  </span>  
  <span className='span2'>Together, we can make a difference <span className='text-light span3'>in the lives of those in need</span>.
  </span>
  <button className='btn btn-lg  bg-transparent text-light absolute -translate-y-3'

  onClick={()=>navigate('/DonorPage')}>Donate Now</button>
  
</caption>

  
  <div className="hero-content">
    <div className="text-page-1">
      <h2 className='text-4xl text-light text-left'>
       Fr. Kevin O'Hara's Foundation | <span style={{color:'#bc8803'}}>
       Driven by shared values and</span>  devotion to serving others</h2>
      <p className='tex text-left'>Please lend your support to this honorable initiative aimed at .<span className='text-light'>
      building a brighter future for the less privileged</span>.</p>
      <div className="curve"></div> 
    </div>
     <div className="curve"></div> 
    <div className="hero-image z-10">
      <img src={image1} alt="Hero Image"/>
    </div>

  </div>
  </div> 
  )
}


export default HeroPage