  
import {Nav, Navbar, Container, NavDropdown, NavLink} from 'react-bootstrap';  
import '../Components/CSS/Home.scss'
import logo from '../Components/IMAGE/OHARAS.webp'
import { HashLink as Link } from 'react-router-hash-link';
import { useNavigate } from "react-router-dom";
import { useState } from 'react';

function NavBar() {  
  const navigate = useNavigate()
  const [showNavbar, setshowNavbar] = useState(true);
   
  const handleItemClick = () => {
    if(window.innerWidth <= 992) {
      setshowNavbar(false);
    }
  }
  const handleItemClick2 = () => {
    if(window.innerWidth <= 992) {
      setshowNavbar(true);
    }
  }


  return (  
   
    <Navbar  expand="lg" fixed='top' className='nav-bar bg-transparent' >  
  <Container >  
      <Navbar.Brand href="/Home" className='logo'>
      
      <img  
        src={logo}  
        width="80"  
        height="80"  
        className="d-inline-block align-top"  
        alt="Brand logo"  
      />

      </Navbar.Brand>  
      <Navbar.Toggle aria-controls="basic-navbar-nav" className='btn-collapse' onClick={handleItemClick2}/>  
      <Navbar.Collapse id="basic-navbar-nav" >  
    {showNavbar && <Nav className="me-auto Links relative " onClick={handleItemClick}>  

          <NavLink href="/Home" className="link1">
          Home
       </NavLink>  

 
     <Link 
     activeClass="active"
     spy={true}
     smooth={true}
     offset={-2000}
     duration={500}
     to="#About"  
     className="link2"> 
     About us
     </Link>  

      <Link to="#Projects"
       className="link3"
       spy={true}
       smooth={true}
       offset={-2000}
       duration={500}
       >
       Projects
      </Link>  


      <Link to="#Support"
       className="link4"
       spy={true}
       smooth={true}
       offset={-2000}
       duration={500}
       >
      Support 
      </Link>  
          <Link to="#ContactUs"
          className="link5"
          spy={true}
          smooth={true}
          offset={-2000}
          duration={500}
          >
          Contact us
         </Link>  
                    
                    
           <Link 
                to="#ContactUs"
                spy={true}
                smooth={true}
                offset={-2000}
                duration={500}
              
              
              className='relative left-1 linkers social-links1 top-0 me-5 d-flex text-decoration-none justify-content-space-evenly align-items-center' id='call'
              style={{minWidth:'80px'}}>
              <span className='me-2 s-link1' style={{fontSize:'1rem', color:'#edf4f6d9'}}>
              Call us: </span>
              
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#eff5f6d9" class="bi bi-telephone-fill" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
              </svg>
              </Link>

        <Link 
         to="#ContactUs"
         spy={true}
         smooth={true}
         offset={-2000}
         duration={500}

        className='relative top-0 linkers  social-links2  d-flex justify-content-space-evenly align-items-center text-decoration-none '><span className='me-2 s-link2' id='email'
         style={{fontSize:'1rem', color:'#f1f5f6d9'}}>Email: </span>
        <svg  xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#f0f4f6d9" class="bi bi-envelope" viewBox="0 0 16 16">
        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
        </svg>
        </Link>
        </Nav>  
}
   
      </Navbar.Collapse>  
    </Container>  
  </Navbar>  
 
  );  
}  
export default NavBar;  